/** @format */
import { useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";

const CBDCMonitorComponent = ({ data }: any) => {
  const [title, setTitle] = useState(data.title);

  const monitorRender = () => {
    if (!data.content) {
      return (
        <Skeleton
          count={4}
          height="58px"
          containerClassName="stock-card-body w-100 p-0"
        />
      );
    }

    const listCol = Object.keys(data.content[0].attributes).filter(
      (k) => k !== "__typename"
    );
    return (
      <div className="stock-card-body">
        <table className="table table-borderless">
          <thead>
            {listCol.map((col, idx) => (
              <th className="text-capitalize pt-2" key={idx}>
                {col}
              </th>
            ))}
          </thead>
          <tbody>
            {data.content.map((row: any, idx: number) => (
              <tr key={idx}>
                {listCol.map((col, idx) => {
                  return (
                    <td style={{ padding: "12px 0px" }} key={idx}>
                      {col.toLocaleLowerCase() === "country" && (
                        <img
                          style={{ width: "16px" }}
                          src={require(`../../assets/img/icon_flags/${row.attributes[
                            col
                          ].toLocaleLowerCase()}.png`)}
                          alt=""
                        />
                      )}{" "}
                      {col.toLocaleLowerCase() === "source"
                        ? row?.attributes[col]?.data?.attributes?.name
                        : row.attributes[col]}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <>
      <div className="tc-trends-news-style1 flex-grow-1">
        <div className="tc-stock-card mb-30 overflow-auto">
          <Link to="/cbdcpulse" className="stock-card-title">
            {title}
            <span className="la la-arrow-circle-o-right color-666" />
          </Link>
          {monitorRender()}
        </div>
      </div>
    </>
  );
};

const CBDCMonitorWrapper: React.FC<any> = (props) => {
  return <CBDCMonitorComponent data={props} />;
};

export { CBDCMonitorWrapper };
