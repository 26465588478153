/** @format */
import { useQuery } from "@apollo/client";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import {
  PINNED_READING_QUERY,
  READING_QUERY,
} from "../../graphql/queries/reading";

import { CBDCMonitorWrapper } from "./monitor";
import { ReadingWrapper } from "./reading";
import {
  CBDC_PULSE_SHORT_QUERY,
  STABLE_COIN_PULSE_SHORT_QUERY,
} from "../../graphql/queries/monitor";
import { StableMonitorWrapper } from "./stablecoin";
import { EVENTS_HOME_QUERY } from "../../graphql/queries/events";
import { EventsHomeWrapper } from "../events";

export const HomeReading = () => {
  const navigate = useNavigate();
  const {
    data: dataReading,
    loading: readingLoading,
    error: errorReading,
  } = useQuery(READING_QUERY);

  const {
    data: dataPinnedReading,
    loading: pinnedReadingLoading,
    error: errorPinnedReading,
  } = useQuery(PINNED_READING_QUERY);

  const {
    data: CBDCData,
    loading: CBDCLoading,
    error: CBDCError,
  } = useQuery(CBDC_PULSE_SHORT_QUERY);

  const {
    data: stableCoinData = {},
    loading: stableCoinLoading,
    error: stableCoinError,
  } = useQuery(STABLE_COIN_PULSE_SHORT_QUERY);

  const {
    data: eventsData,
    loading: eventsLoading,
    error: eventsError,
  } = useQuery(EVENTS_HOME_QUERY);

  return (
    <div className="container">
      <div className="tc-widget-popular-style1 border-1 border-bottom brd-gray pt-10 pb-10">
        <div className="row gx-3">
          <div className="col-12 col-lg-8 row">
            <p className="color-000 text-uppercase mb-10 ltspc-1">
              <span> Newest Readings </span>{" "}
              <i className="la la-angle-right ms-1" />
            </p>

            {pinnedReadingLoading && (
              <div className="col-12 col-lg-4">
                <Skeleton
                  height="100%"
                  containerClassName="library-skeleton-card"
                />
              </div>
            )}

            {dataPinnedReading?.pinnedReading?.data && (
              <>
                <div className="col-12 col-lg-4">
                  <ReadingWrapper
                    book={dataPinnedReading?.pinnedReading?.data}
                  />
                </div>
              </>
            )}

            {readingLoading && (
              <>
                <div className="col-12 col-lg-4">
                  <Skeleton
                    height="100%"
                    containerClassName="library-skeleton-card"
                  />
                </div>
                <div className="col-12 col-lg-4">
                  <Skeleton
                    height="100%"
                    containerClassName="library-skeleton-card"
                  />
                </div>
              </>
            )}

            {dataReading?.readings?.data.map((book: any, idx: number) => {
              if (dataPinnedReading?.pinnedReading?.data && idx > 1)
                return <></>;
              else {
                return (
                  <div className="col-12 col-lg-4" key={idx}>
                    <ReadingWrapper book={book} />
                  </div>
                );
              }
            })}
          </div>

          <div className="col-12 col-lg-4 flex-grow-1">
            <p className="color-000 text-uppercase mb-10 ltspc-1">
              <span> Events </span>{" "}
              <i className="la la-angle-right ms-1" />
            </p>

            {eventsLoading && (
              <Skeleton
                height="100%"
                containerClassName="library-skeleton-card"
              />
            )}

            {eventsData?.events?.data && (
              <EventsHomeWrapper
                content={eventsData?.events?.data}
              />
            )}
          </div>

          <div className="col-12 col-lg-8 mt-3">
            {CBDCLoading && (
              <Skeleton
                height="100%"
                containerClassName="library-skeleton-card"
              />
            )}

            {CBDCData?.cbdcMonitors?.data && (
              <CBDCMonitorWrapper
                title={"CBDC Pulse™"}
                content={CBDCData.cbdcMonitors.data}
              />
            )}
          </div>

          <div className="col-12 col-lg-4 mt-3">
            {stableCoinLoading && (
              <Skeleton
                height="100%"
                containerClassName="library-skeleton-card"
              />
            )}

            {stableCoinData?.stablecoins?.data && (
              <StableMonitorWrapper
                title={"Stablecoin Pulse™"}
                content={stableCoinData.stablecoins.data}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
