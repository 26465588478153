/** @format */

import axios from "axios";
import {
  IBookmark,
  ICredentials,
  IRegisterCredentials,
  ISubscriber,
} from "../interface";

const BASE_URL = process.env.REACT_APP_CI_API_URL;

const axiosInstance = axios.create();

const setAxiosHeader = () => {
  const token = localStorage.getItem("token")?.replace(/"/g, "");
  axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

const postRequest = (endpoint: string, payload: any) => {
  setAxiosHeader();
  return axiosInstance.post(BASE_URL + endpoint, payload);
};

const putRequest = (endpoint: string, payload: any) => {
  setAxiosHeader();
  return axiosInstance.put(BASE_URL + endpoint, payload);
};

const getRequest = (endpoint: string) => {
  setAxiosHeader();
  return axiosInstance.get(BASE_URL + endpoint);
};

export const loginUser = async (credentials: ICredentials) => {
  const endpoint = "/auth/login";
  const response = axios.post(BASE_URL + endpoint, credentials);
  return response;
};

export const registerUser = async (credentials: IRegisterCredentials) => {
  const endpoint = "/auth/register";
  const response = axios.post(BASE_URL + endpoint, credentials);
  return response;
};

export const postSubscribe = async (subscriber: ISubscriber) => {
  const endpoint = "/subscriber";
  const response = axios.post(BASE_URL + endpoint, subscriber);
  return response;
};

export const postBookmark = async (payload: IBookmark) => {
  const endpoint = "/bookmark";
  const response = postRequest(endpoint, payload);
  return response;
};

export const postStoryViewed = async (payload: any) => {
  const endpoint = "/newsitem/view-event";
  const response = postRequest(endpoint, payload);
  return response;
};

export const getBookmarks = async () => {
  const endpoint = "/newsitem/bookmark";
  const response = getRequest(endpoint);
  return response;
};

export const getStoryView = async () => {
  const endpoint = "/newsitem/view-event";
  const response = getRequest(endpoint);
  return response;
};

export const getUser = async () => {
  const endpoint = "/user/profile";
  const response = getRequest(endpoint);
  return response;
};

export const putUserResetPassword = async (payload: any) => {
  const endpoint = "/user/reset-password";
  const response = putRequest(endpoint, payload);
  return response;
};

export const getDowloadable = async () => {
  const endpoint = "/reading/downloadable";
  const response = getRequest(endpoint);
  return response;
};

export const postTrackingDownload = async (payload: any) => {
  const endpoint = "/reading/downloaded";
  const response = postRequest(endpoint, payload);
  return response;
};

export const getVerifyUser = async (token: string) => {
  const endpoint = "/auth/verify/" + token;
  const response = getRequest(endpoint);
  return response;
};

export const postForgotPassword = async (payload: { email: string }) => {
  const endpoint = "/auth/forgot-password/";
  const response = postRequest(endpoint, payload);
  return response;
};

export const postResetPassword = async (payload: {
  forgotToken: string;
  newPass: string;
}) => {
  const endpoint = "/auth/reset-password/";
  const response = postRequest(endpoint, payload);
  return response;
};


