/** @format */
import { useEffect, useState } from "react";
import { createColumnHelper } from "@tanstack/react-table";
import { Box, Text } from "@chakra-ui/react";
import CustomSpinner from "../../components/CustomSpiner";
import { DataTable } from "../../components/Datatable";
import { getBookmarks } from "../../api/BE";
import { STORIES_BY_IDS_QUERY } from "../../graphql/queries/story";
import { useLazyQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";

export const Bookmark = () => {
  const [data, setData] = useState<any>([]);
  const [ids, setIds] = useState([]);
  const navigate = useNavigate();

  const [getStories, { loading, data: stories }] = useLazyQuery(
    STORIES_BY_IDS_QUERY,
    {
      variables: { ids: ids },
      onCompleted(dataQuery) {
        const result = data.map((b: any, idx: number) => ({
          ...b,
          headline: dataQuery.stories.data[idx].attributes.headline,
        }));

        setData(result.reverse());
      },
    }
  );

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/auth");
    }

    const asyncFunction = async () => {
      const res = await getBookmarks();
      setIds(res.data.map((b: any) => b.id));
      setData(res.data);
      getStories();
    };

    asyncFunction().catch((err) => {
      console.log(err);
    });
  }, []);

  const columnHelper = createColumnHelper<any>();

  const columns: any = [
    columnHelper.accessor((row) => row.id, {
      cell: (info) => info.getValue(),
      header: "ID",
    }),
    columnHelper.accessor((row) => row.headline, {
      cell: (info) => info.getValue(),
      header: "Headline",
    }),
  ];

  return loading ? (
    <Box m={5}>
      <CustomSpinner />
    </Box>
  ) : (
    <div className="container">
      <DataTable columns={columns} data={data || []} />
    </div>
  );
};
