/** @format */

import { QueryResult, useLazyQuery, useQuery } from "@apollo/client";
import {
  PINNED_READING_QUERY,
  READING_QUERY,
} from "../../graphql/queries/reading";
import CustomSpinner from "../../components/CustomSpiner";
import { Box, Button, Flex, Image, Link, SimpleGrid } from "@chakra-ui/react";
import { Datum } from "../../interface/IReading";
import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { postTrackingDownload } from "../../api/BE";
import { NextNavigate } from "../../App";
import Pagination from "../../components/Pagination";

export const ReadingLibrary = () => {
  const { setNextNavigate } = useContext(NextNavigate);
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(20);
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      setNextNavigate("/library");
      navigate("/auth");
    } else {
      setNextNavigate("/home");
    }
  }, []);

  const [getReading] = useLazyQuery(READING_QUERY, {
    variables: { limit: 100 },
    onCompleted(dataQuery) {
      setData((pre: any) => [...pre, ...dataQuery?.readings?.data]);
    },
  });

  const [getPinnedReading] = useLazyQuery(PINNED_READING_QUERY, {
    onCompleted(dataQuery) {
      setData(() => [dataQuery?.pinnedReading?.data]);
      getReading();
    },
  });

  useEffect(() => {
    getPinnedReading();
    setLoading(false);
  }, []);

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  // const currentRecords = data.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(data.length / recordsPerPage)

  return (
    <>
      {/* <Flex flexDirection={"column"} justifyContent={"space-between"}>
              <Image
                cursor={"pointer"}
                src={
                  dataPinnedReading?.pinnedReading?.data?.attributes
                    .thumbnail_url
                }
                alt=""
                width={322.5}
                height={419.25}
                onClick={() =>
                  navigate(
                    "/library/" + dataPinnedReading?.pinnedReading?.data?.id
                  )
                }
              />
            </Flex> */}
      {loading || !data.length ? (
        <Box m={5}>
          <CustomSpinner />
        </Box>
      ) : (
        <div className="container mt-4 mb-4">
          <SimpleGrid columns={4} spacingX="40px" spacingY="40px" mb={20}>
            {data
              .slice(indexOfFirstRecord, indexOfLastRecord)
              .map((read: Datum) => (
                <Flex
                  flexDirection={"column"}
                  key={read?.id}
                  justifyContent={"space-between"}
                >
                  <Image
                    cursor={"pointer"}
                    src={read?.attributes?.thumbnail_url}
                    alt=""
                    width={322.5}
                    height={419.25}
                    onClick={() => navigate("/library/" + read?.id)}
                  />
                  {/* <Link
                  href={isDownloadable ? read.attributes.pdf_url : "#"}
                  target="_blank"
                >
                  <Button
                    w="100%"
                    onClick={() => handleTrackingDownload(+read?.id)}
                    isDisabled={!isDownloadable}
                  >
                    Download
                  </Button>
                </Link> */}
                </Flex>
              ))}
          </SimpleGrid>

          <Pagination
            nPages={nPages}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
          {/* <div className="row row-cols-12 row-cols-lg-4 g-3">
            <Flex flexDirection={"column"}>
              <img
                src={
                  dataPinnedReading?.pinnedReading?.data?.attributes
                    .thumbnail_url
                }
                alt=""
                onClick={() =>
                  navigate(
                    "/library/" + dataPinnedReading?.pinnedReading?.data?.id
                  )
                }
              />
              <Link
                href={
                  dataPinnedReading?.pinnedReading?.data?.attributes.pdf_url
                }
                target="_blank"
              >
                <Button
                  w="100%"
                  onClick={() =>
                    handleTrackingDownload(
                      +dataPinnedReading?.pinnedReading?.data?.id
                    )
                  }
                >
                  Download
                </Button>
              </Link>
            </Flex>
          </div> */}
        </div>
      )}
    </>
  );
};
