/** @format */

import { Flex, Button, Text } from "@chakra-ui/react";

type Props = {
  nPages: number;
  currentPage: number;
  setCurrentPage: (currentPage: number) => void;
};

const Pagination = ({ nPages, currentPage, setCurrentPage }: Props) => {
  const pageNumbers = [...Array(nPages + 1).keys()].slice(1);

  const goToNextPage = () => {
    if (currentPage !== nPages) setCurrentPage(currentPage + 1);
  };
  const goToPrevPage = () => {
    if (currentPage !== 1) setCurrentPage(currentPage - 1);
  };
  return (
    <>
      <Flex justifyContent={"center"} alignItems={"center"} gap={2} mt="2">
        <Button className="p-1" onClick={() => goToPrevPage()}>
          {"<"}
        </Button>

        {pageNumbers.map((pgNumber) => (
          <Button
            className={`page-item`}
            onClick={() => setCurrentPage(pgNumber)}
            isActive={currentPage === pgNumber}
            _active={{
              bg: "main",
              color: "black",
            }}
          >
            <Text>{pgNumber}</Text>
          </Button>
        ))}
        <Button className="p-1" onClick={() => goToNextPage()}>
          {">"}
        </Button>
      </Flex>
    </>
  );
};

export default Pagination;
