/** @format */

import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { STORY_DETAIL_QUERY } from "../../../graphql/queries/story";
import { TwitterShareButton, TwitterIcon } from "react-share";
import CustomSpinner from "../../../components/CustomSpiner";
import { ClipboardCopy } from "../../../components/ClipboardCopy";
import { postBookmark, postStoryViewed } from "../../../api/BE";

export const StoryDetailPage = () => {
  const [dataDetail, setDataDetail] = useState<any>();
  const navigate = useNavigate();
  const { id } = useParams();
  const { data, loading } = useQuery(STORY_DETAIL_QUERY, {
    variables: { id },
  });

  useEffect(() => {
    if (!id) {
      navigate("/home");
      return;
    }

    if (!loading) {
      setDataDetail(data.story.data);
    }

    const asyncfunction = async () => {
      const token = localStorage.getItem("token");
      if (token) {
        await postStoryViewed({ storyId: parseInt(id) });
      }
    };

    asyncfunction().catch((error) => {
      if (error.response.status === 401 || error.response.status === 403) {
        navigate("/auth");
      }
    });
  }, [id, loading]);

  const handleSetBookmark = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/auth");
    }

    if (!id) {
      navigate("/home");
      return;
    }

    try {
      await postBookmark({ storyId: parseInt(id) });
    } catch (error: any) {
      if (error.response.status === 401 || error.response.status === 403) {
        navigate("/auth");
      }
    }
  };

  return (
    <>
      {loading ? (
        <div className="m-5">
          <CustomSpinner />
        </div>
      ) : dataDetail ? (
        <main className="tc-single-post-creative-page">
          {/* ====== start tc-main-post-style1 ====== */}
          <section className="tc-main-post-style1 pb-60">
            <div className="container">
              <div className="tc-main-post-title pt-40 pb-40">
                <h2 className="title">{dataDetail?.attributes.headline}</h2>
              </div>
              <div className="meta-nav pt-30 pb-30 border-top border-1 brd-gray">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="author-side color-666 fsz-13px">
                      {dataDetail?.attributes.writer.data && (
                        <div className="author me-40 d-flex d-lg-inline-flex align-items-center">
                          <span>By</span>
                          <a
                            href="/"
                            className="text-decoration-underline text-primary ms-1"
                          >
                            {dataDetail?.attributes.writer.data.attributes
                              .first_name +
                              dataDetail?.attributes.writer.data.attributes
                                .last_name}
                          </a>
                        </div>
                      )}

                      <span className="me-40">
                        <a href="/">
                          <i className="la la-calendar me-1" />{" "}
                          {new Date(dataDetail.attributes.published_dt)
                            .toDateString()
                            .substring(4)}
                        </a>
                      </span>

                      {dataDetail?.attributes.category.data && (
                        <span className="me-40">
                          {
                            dataDetail?.attributes.category.data.attributes
                              .category
                          }
                        </span>
                      )}

                      {dataDetail?.attributes.geography.data && (
                        <span className="me-40">
                          {dataDetail?.attributes.geography.data.attributes.geo}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6 text-lg-end">
                    <div className="d-flex justify-content-end links-side color-000 fsz-13px">
                      <ClipboardCopy text={window.location.href}>
                        <span className="me-40">
                          <i className="la la-link me-1" /> Copy Link
                        </span>
                      </ClipboardCopy>
                      <span onClick={handleSetBookmark}>
                        <i className="la la-bookmark me-1" /> Bookmark
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              {dataDetail.attributes.header_img_url && (
                <div className="tc-main-post-img img-cover mb-50">
                  <img
                    src={dataDetail.attributes.header_img_url}
                    alt="thumbnail"
                  />
                </div>
              )}

              <div className="tc-main-post-content color-000">
                <div className="row">
                  <div className="col-lg-2">
                    {/* <div className="sharing">
                      <p className="text-uppercase mb-20">Share</p>
                      <div className="share-icons">
                        <TwitterShareButton
                          url={window.location.href}
                          title="Check this out…"
                        >
                          <TwitterIcon size={32} round />
                        </TwitterShareButton>
                      </div>
                    </div> */}
                  </div>
                  <div
                    id="artical"
                    className="col-lg-10"
                    dangerouslySetInnerHTML={{
                      __html: dataDetail?.attributes?.story,
                    }}
                  ></div>

                  {dataDetail?.attributes.source_url &&
                    dataDetail.attributes.source.data && (
                      <div className="me-40 d-flex d-lg-inline-flex align-items-center">
                        <span>Source: </span>
                        <a
                          href={dataDetail.attributes.source_url}
                          target="_blank"
                          rel="noreferrer"
                          className="text-decoration-underline text-primary ms-1"
                        >
                          {dataDetail.attributes.source.data.attributes.name}
                        </a>
                      </div>
                    )}
                </div>
              </div>
            </div>
          </section>
        </main>
      ) : (
        <></>
      )}
    </>
  );
};
