/** @format */

import { gql } from "@apollo/client";

export const READING_QUERY = gql`
  query ($limit: Int = 3) {
    readings(pagination: { limit: $limit }, sort: "createdAt:desc") {
      data {
        id
        attributes {
          title
          pdf_url
          thumbnail_url
          pages
        }
      }
    }
  }
`;

export const READING_DETAIL_QUERY = gql`
  query ($id: ID!) {
    reading(id: $id) {
      data {
        id
        attributes {
          title
          pdf_url
          thumbnail_url
          pages
        }
      }
    }
  }
`;

export const PINNED_READING_QUERY = gql`
  query {
    pinnedReading {
      data {
        id
        attributes {
          title
          pdf_url
          thumbnail_url
          pages
        }
      }
    }
  }
`;
