/** @format */

import { gql } from "@apollo/client";

export const FEATURE_QUERY = gql`
  query GetFeature {
    feature {
      data {
        id
        attributes {
          story
          headline
          published_dt
          header_img_url
          writer {
            data {
              attributes {
                first_name
                last_name
              }
            }
          }
          source {
            data {
              attributes {
                name
                home_url
              }
            }
          }
          source_url
        }
      }
    }
  }
`;
