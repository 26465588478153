/** @format */
import { useEffect, useState } from "react";
import { getKucoinSocketToken, getKucoinStableCoin } from "../../api/kucoin";
import { util } from "../../api/kucoinWebSocketUtils";
import { createColumnHelper } from "@tanstack/react-table";
import { Box, Text } from "@chakra-ui/react";
import { StablecoinData } from "../../interface";
import CustomSpinner from "../../components/CustomSpiner";
import { DataTable } from "../../components/Datatable";

export const Stablecoin = () => {
  const [tempMsg, setTempMsg] = useState({} as any);
  const [loading, setLoading] = useState(true);

  const pairs = localStorage.getItem("list-pairs") || [];
  const [listPair, setListPair] = useState([
    { symbol: "USDT-TUSD" },
    { symbol: "USDC-USDT" },
    { symbol: "USDT-DAI" },
    { symbol: "USDT-BRL" },
    { symbol: "USDT-EUR" },
    { symbol: "USDT-GBP" },
    { symbol: "BUSD-USDT" },
    { symbol: "BUSD-USDC" },
    { symbol: "CUSD-USDT" },
    { symbol: "CELO-USDT" },
    { symbol: "OUSD-USDT" },
  ]);

  useEffect(() => {
    const kucoinSocketToken = async () => {
      const list = await getKucoinStableCoin(listPair);
      setListPair(list);
      localStorage.setItem("list-pairs", JSON.stringify(list));
      setLoading(false);

      const wsToken = await getKucoinSocketToken();

      if (!wsToken) return;

      const w = new WebSocket(wsToken);
      w.onopen = () => {
        console.log("Socket Connected");
        // Subscribe
        setInterval(() => {
          w.send(util.ping());
        }, 20000);

        listPair.forEach((p) => {
          w.send(util.subscribe("/market/snapshot:", [p.symbol]));
        });
      };

      w.onmessage = (msg) => {
        setTempMsg(JSON.parse(msg.data));
      };
    };

    kucoinSocketToken().catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    if (tempMsg.type !== "message") return;

    const pair = tempMsg.topic.replace("/market/snapshot:", "");
    const pairIndex = listPair.findIndex((coin: any) => coin.symbol === pair);
    const newList = [...listPair];
    newList[pairIndex] = tempMsg.data.data;
    setListPair(newList);
    localStorage.setItem("list-pairs", JSON.stringify(newList));
  }, [tempMsg]);

  const columnHelper = createColumnHelper<StablecoinData>();

  const columns: any = [
    columnHelper.accessor((row) => row.symbol, {
      cell: (info) => info.getValue().replace("-", " - "),
      header: "Stablecoin",
    }),
    columnHelper.accessor((row) => row.averagePrice, {
      cell: (info) => parseFloat(info.getValue()).toFixed(3),
      header: "Price",
    }),
    columnHelper.accessor((row) => row.changeRate, {
      cell: (info) => (
        <Text color={parseFloat(info.getValue()) >= 0 ? "red" : "green"}>
          {info.getValue()}
        </Text>
      ),
      header: "Exchange",
    }),
    columnHelper.accessor((row) => row.low, {
      cell: (info) => parseFloat(info.getValue()).toFixed(3),
      header: "Low",
    }),
    columnHelper.accessor((row) => row.high, {
      cell: (info) => parseFloat(info.getValue()).toFixed(3),
      header: "High",
    }),
  ];

  return loading ? (
    <Box m={5}>
      <CustomSpinner />
    </Box>
  ) : (
    <DataTable columns={columns} data={listPair || []} />
  );
};
