/** @format */

export const Footer = () => {
  return (
    <footer className="footer-style1">
      <div className="container">
        {/* <div className="tc-subscribe-style1">
          <div className="row justify-content-between align-items-center">
            <div className="col-lg-4">
              <div className="sub-info">
                <h5>Get the best blog stories into your inbox</h5>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="sub-form">
                <div className="form-group">
                  <span className="icon">
                    <i className="la la-envelope" />
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter your email"
                  />
                  <button>subscribe</button>
                </div>
                <p className="mt-2 color-666 fsz-13px fst-italic">
                  By subscribing, you accepted the our{" "}
                  <a
                    href="#"
                    className="text-decoration-underline fst-normal color-000"
                  >
                    Policy
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div> */}
        <div className="content">
          <div className="row gx-lg-5">
            <div className="col-lg-4">
              <div className="info">
                <div className="foot-logo">
                  <img
                    src={process.env.PUBLIC_URL + "/logo.png"}
                    alt=""
                    className="dark-none"
                  />
                  <img
                    src={process.env.PUBLIC_URL + "/logo.png"}
                    alt=""
                    className="light-none"
                  />
                </div>
                <div className="data-info">
                  <p>Scifn</p>
                  <p>43 W 23rd Street</p>
                  <p>New York, NY 10010</p>
                  <p>
                    <b> Email : </b> hello @ scifn.com
                  </p>
                </div>
                <div className="social-links">
                  <a
                    href="https://linkedin.com/company/currencyinsider"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="la la-linkedin" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="link-group">
                <div className="title">Links</div>
                <ul>
                  <li>
                    <a
                      href="https://scifn.com"
                      target="_blank"
                      rel="noreferrer"
                      className="f-link"
                    >
                      Scifn
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://scifn.thinkific.com"
                      target="_blank"
                      rel="noreferrer"
                      className="f-link"
                    >
                      Learning
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://blockchain101.com"
                      target="_blank"
                      rel="noreferrer"
                      className="f-link"
                    >
                      Blockchain 101
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://cbdctt.com"
                      target="_blank"
                      rel="noreferrer"
                      className="f-link"
                    >
                      Digital Currency Think Tank
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://aifin.nyc"
                      target="_blank"
                      rel="noreferrer"
                      className="f-link"
                    >
                      AI+FIN
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://deficonf.nyc"
                      target="_blank"
                      rel="noreferrer"
                      className="f-link"
                    >
                      DEFICON
                    </a>
                  </li>
                </ul>
              </div>
              {/* <div className="link-group">
                <div className="title">Politics</div>
                <ul>
                  <li>
                    <a href="#" className="f-link">
                      Capital Journal
                    </a>
                  </li>
                  <li>
                    <a href="#" className="f-link">
                      National Security
                    </a>
                  </li>
                  <li>
                    <a href="#" className="f-link">
                      Politics Video
                    </a>
                  </li>
                </ul>
              </div> */}
            </div>
            {/* <div className="col-lg-2">
              <div className="link-group">
                <div className="title">Technology</div>
                <ul>
                  <li>
                    <a href="#" className="f-link">
                      Personal Technology
                    </a>
                  </li>
                  <li>
                    <a href="#" className="f-link">
                      Product Reviews
                    </a>
                  </li>
                  <li>
                    <a href="#" className="f-link">
                      Video Games
                    </a>
                  </li>
                  <li>
                    <a href="#" className="f-link">
                      Discover
                    </a>
                  </li>
                  <li>
                    <a href="#" className="f-link">
                      Knowledge
                    </a>
                  </li>
                </ul>
              </div>
              <div className="link-group">
                <div className="title">Lifestyle</div>
                <ul>
                  <li>
                    <a href="#" className="f-link">
                      Lifestyle Trends
                    </a>
                  </li>
                  <li>
                    <a href="#" className="f-link">
                      Family
                    </a>
                  </li>
                  <li>
                    <a href="#" className="f-link">
                      Psychology
                    </a>
                  </li>
                  <li>
                    <a href="#" className="f-link">
                      Fashion
                    </a>
                  </li>
                  <li>
                    <a href="#" className="f-link">
                      Foods
                    </a>
                  </li>
                  <li>
                    <a href="#" className="f-link">
                      Culture
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="link-group">
                <div className="title">Entertainment</div>
                <ul>
                  <li>
                    <a href="#" className="f-link">
                      Music &amp; Podcasts
                    </a>
                  </li>
                  <li>
                    <a href="#" className="f-link">
                      Art &amp; Design
                    </a>
                  </li>
                  <li>
                    <a href="#" className="f-link">
                      Movies
                    </a>
                  </li>
                </ul>
              </div>
              <div className="link-group">
                <div className="title">Sport</div>
                <ul>
                  <li>
                    <a href="#" className="f-link">
                      Football
                    </a>
                  </li>
                  <li>
                    <a href="#" className="f-link">
                      Tennis
                    </a>
                  </li>
                  <li>
                    <a href="#" className="f-link">
                      F-1 Racing Tour
                    </a>
                  </li>
                  <li>
                    <a href="#" className="f-link">
                      NBA
                    </a>
                  </li>
                  <li>
                    <a href="#" className="f-link">
                      Basketball
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="link-group">
                <div className="title">Travel</div>
                <ul>
                  <li>
                    <a href="#" className="f-link">
                      Discovery
                    </a>
                  </li>
                  <li>
                    <a href="#" className="f-link">
                      Flight Tickets &amp; Hotel
                    </a>
                  </li>
                  <li>
                    <a href="#" className="f-link">
                      Tour
                    </a>
                  </li>
                  <li>
                    <a href="#" className="f-link">
                      Destination
                    </a>
                  </li>
                  <li>
                    <a href="#" className="f-link">
                      Experience &amp; Reviews
                    </a>
                  </li>
                </ul>
              </div>
              <div className="link-group">
                <div className="title">Help Center</div>
                <ul>
                  <li>
                    <a href="#" className="f-link">
                      About Us
                    </a>
                  </li>
                  <li>
                    <a href="#" className="f-link">
                      Contact
                    </a>
                  </li>
                  <li>
                    <a href="#" className="f-link">
                      Partners Programe
                    </a>
                  </li>
                  <li>
                    <a href="#" className="f-link">
                      Privacy Policy
                    </a>
                  </li>
                  <li>
                    <a href="#" className="f-link">
                      Term &amp; Conditions
                    </a>
                  </li>
                </ul>
              </div>
            </div> */}
          </div>
        </div>
        <div className="foot text-center py-4 border-1 border-top brd-gray">
          <p className="fsz-13px color-666">
            © 2023 Copyright{" "}
            <a href="#" className="color-000">
              Scifn
            </a>
            . All Rights Reserved.{" "}
          </p>
        </div>
      </div>
      {/* ====== start to top button ====== */}
      <a href="#" className="to_top show">
        <i className="la la-angle-up" />
      </a>
      {/* ====== end to top button ====== */}
    </footer>
  );
};
