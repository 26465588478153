/** @format */

import { useNavigate } from "react-router-dom";

export interface ReadingProps {
  book: any;
}
const ReadingComponent = ({ book }: any) => {
  const navigate = useNavigate();
  return (
    <div className="main-card">
      <div
        className="img img-cover w-100"
        onClick={() => navigate("/library/" + book.id)}
      >
        <img src={book.attributes?.thumbnail_url} alt="" />
      </div>
    </div>
  );
};

const ReadingWrapper: React.FC<ReadingProps> = ({ book }) => {
  return <ReadingComponent book={book} />;
};

export { ReadingWrapper };
