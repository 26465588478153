/** @format */
import { createColumnHelper } from "@tanstack/react-table";
import { Box, Flex, Image, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import CustomSpinner from "../../components/CustomSpiner";
import { DataTable } from "../../components/Datatable";
import { useQuery } from "@apollo/client";
import { EVENTS_QUERY } from "../../graphql/queries/events";

export const EventTable = () => {
  const { data, loading, error } = useQuery(EVENTS_QUERY);

  const columnHelper = createColumnHelper<any>();

  const columns: any = [
    columnHelper.accessor("Title", {
      cell: (info) => (
        <>
          <Image
            w={200}
            objectFit="cover"
            src={
              process.env.REACT_APP_API_URL +
              info.row.original.attributes?.banner_img?.data?.attributes?.url
            }
            alt=""
          />
        </>
      ),
    }),
    columnHelper.accessor(
      (row) => row?.attributes?.organization?.data?.attributes?.name,
      { cell: (info) => info.getValue(), header: "Organization" }
    ),
    columnHelper.accessor(" ", {
      cell: (info: any) => (
        <>
          <Flex>
            <Text textDecor={"underline"} cursor={"pointer"}>
              <Link target="_blank" to={`/events/${info.row.original.id}`}>
                Details
              </Link>
            </Text>{" "}
            &nbsp;|&nbsp;{" "}
            <Text textDecor={"underline"} cursor={"pointer"}>
              <Link target="_blank" to={info.row.original.attributes?.rsvp_url}>
                Register
              </Link>
            </Text>
          </Flex>
        </>
      ),
    }),
  ];

  return loading ? (
    <Box m={5}>
      <CustomSpinner />
    </Box>
  ) : (
    <div className="container mt-10">
      <DataTable columns={columns} data={data?.events?.data || []} />
    </div>
  );
};
