/** @format */

import { gql } from "@apollo/client";

export const STORIES_QUERY = gql`
  query GetAllStories($limit: Int = 12, $sort: [String] = ["createdAt:desc"]) {
    stories(sort: $sort, pagination: { limit: $limit }) {
      data {
        id
        attributes {
          createdAt
          story
          headline
          published_dt
          header_img_url
          category {
            data {
              attributes {
                category
              }
            }
          }
          writer {
            data {
              attributes {
                first_name
                last_name
              }
            }
          }
          source {
            data {
              attributes {
                name
                home_url
              }
            }
          }
          source_url
        }
      }
    }
  }
`;

export const STORY_DETAIL_QUERY = gql`
  query GetStoryDetail($id: ID) {
    story(id: $id) {
      data {
        id
        attributes {
          createdAt
          story
          headline
          published_dt
          header_img_url
          geography {
            data {
              attributes {
                geo
              }
            }
          }
          category {
            data {
              attributes {
                category
              }
            }
          }
          writer {
            data {
              attributes {
                first_name
                last_name
              }
            }
          }
          source {
            data {
              attributes {
                name
                home_url
              }
            }
          }
          source_url
        }
      }
    }
  }
`;

export const STORIES_BY_IDS_QUERY = gql`
  query GetStoriesDetail($ids: [ID]) {
    stories(filters: { id: { in: $ids } }) {
      data {
        id
        attributes {
          headline
        }
      }
    }
  }
`;
