/** @format */

import { extendTheme } from "@chakra-ui/react";
import { buttonTheme } from "./components/ButtonTheme";

const theme = extendTheme({
  fonts: {
    heading: `'Source Code Pro', sans-serif`,
    body: `'Source Code Pro', sans-serif`,
  },
  colors: {
    main: "#fefd07",
    topcta: "#fefd07",
  },
  components: {
    Button: buttonTheme,
  },
});

export default theme;
