/** @format */
import {
  Flex,
  Box,
  Stack,
  FormControl,
  InputGroup,
  InputLeftElement,
  Input,
  Button,
  chakra,
  FormErrorMessage,
  useToast,
} from "@chakra-ui/react";

import { AiOutlineMail } from "react-icons/ai";
import { Field, FieldProps, Form, Formik } from "formik";
import { postForgotPassword } from "../../api/BE";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { CREATE_USER_MUTATION } from "../../graphql/queries/user";

const CAiOutlineMail = chakra(AiOutlineMail);

export const ForgotPasswordPage = () => {
  const navigate = useNavigate();

  const toast = useToast({
    position: "bottom-right",
  });

  function validate(value: string) {
    let error;
    if (!value) {
      error = " is required";
    }
    return error;
  }
  return (
    <Flex
      flexDirection="column"
      width="100wh"
      height="100vh"
      justifyContent="center"
      alignItems="center"
    >
      <Stack
        flexDir="column"
        mb="2"
        justifyContent="center"
        alignItems="center"
      >
        <Box minW={{ base: "90%", md: "468px" }}>
          <Formik
            initialValues={{
              email: "",
            }}
            onSubmit={async (values, actions) => {
              await postForgotPassword(values);
              actions.setSubmitting(false);
              toast({
                title: "If this user exists, we have sent you a password reset email, you will be redirect to home soon",
                status: "success",
              });

              setTimeout(() => navigate("/home"), 5000)
              
              return;
            }}
          >
            {(props) => (
              <Form>
                <Field name="email" validate={validate}>
                  {({ field, form }: FieldProps) => (
                    <FormControl
                      mb={4}
                      isInvalid={!!form.errors.email && !!form.touched.email}
                    >
                      <InputGroup>
                        <InputLeftElement
                          pointerEvents="none"
                          color="black"
                          children={<CAiOutlineMail color="black" />}
                        />
                        <Input
                          {...field}
                          placeholder="Email"
                          borderRadius="none"
                        />
                      </InputGroup>
                      <FormErrorMessage>
                        {"Email" + form.errors.email}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Flex
                  alignItems={"center"}
                  mt={8}
                  justifyContent="space-between"
                >
                  <Button
                    type="submit"
                    variant="solid"
                    backgroundColor="black"
                    color="main"
                    borderRadius="none"
                    width="full"
                    isLoading={props.isSubmitting}
                  >
                    Submit
                  </Button>
                </Flex>
              </Form>
            )}
          </Formik>
        </Box>
      </Stack>
    </Flex>
  );
};
