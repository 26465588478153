/** @format */

import { QueryResult, useQuery } from "@apollo/client";
import { STORIES_QUERY } from "../../graphql/queries/story";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { click } from "@testing-library/user-event/dist/click";

export const Story = () => {
  const [thirdRowData, setThirdRowData] = useState<any[]>();
  const [secondRowData, setSecondRowData] = useState<any[]>();
  const { data, loading } = useQuery(STORIES_QUERY);

  const ndRow = useRef<HTMLDivElement>(null);
  const rdRow = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!loading && data) {
      if (data?.stories.data.length < 7) {
        setSecondRowData(data?.stories.data);
      } else {
        setSecondRowData(data?.stories.data.slice(0, 6));
        setThirdRowData(data?.stories.data.slice(6));
      }
    }
  }, [data, loading]);

  const navigate = useNavigate();

  const navigateDetailFutre = (id: string) => {
    if (id) {
      navigate(`/news/detail/${id}`);
    }
  };

  const renderStories = (rowData: any, refElemet: any) => (
    <div className="tc-post-list-style2">
      <div className="items">
        {data &&
          rowData?.map((item: any, idx: number) => {
            if (idx === 0) {
              return (
                <div className="item item-first d-flex flex-column" ref={refElemet}>
                  <div className="img img-cover th-200">
                    <img src={item.attributes.header_img_url} alt="" />
                  </div>
                  <div className="content pt-20 d-flex flex-column h-100">
                    <span className="news-cat color-999 fsz-13px text-uppercase mb-10">
                      CBDC
                    </span>
                    <h5 className="title-first ltspc--1 mb-10 flex-grow-1">
                      <a href={`/news/detail/${item.id}`}>
                        {item.attributes.headline}
                      </a>
                    </h5>
                    <div className="text color-666">
                      {item.attributes?.story
                        .replace(/<\/?[^>]+(>|$)/g, "")
                        .replaceAll("&nbsp;", " ")
                        .substring(0, 70)}{" "}
                      [...]
                    </div>
                    <div className="meta-bot lh-1 mt-20">
                      <ul className="d-flex">
                        <li className="date me-5">
                          <span>
                            <i className="la la-calendar me-2" />{" "}
                            {new Date(item.attributes.published_dt)
                              .toDateString()
                              .substring(4)}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              );
            } else {
              return (
                <div
                  className="item"
                  key={item.id}
                  onClick={() => navigateDetailFutre(item.id)}
                >
                  <div className="row gx-3 align-items-center">
                    <div className="col-4">
                      <div className="img th-70 img-cover">
                        <img src={item.attributes.header_img_url} alt="" />
                      </div>
                    </div>
                    <div className="col-8">
                      <div className="content">
                        <h5 className="title ltspc--1">
                          <span className="hover-underline">
                            {item.attributes.headline.length > 55 ? (
                              <>
                                {item.attributes.headline
                                  .replace(/<\/?[^>]+(>|$)/g, "")
                                  .replaceAll("&nbsp;", " ")
                                  .substring(0, 55)}{" "}
                                ...
                              </>
                            ) : (
                              item.attributes.headline
                            )}
                          </span>
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
          })}
      </div>
    </div>
  );

  useEffect(() => {
    if (data?.stories.data.length > 7 && ndRow.current && rdRow.current) {
      if(ndRow.current.offsetHeight > rdRow.current.offsetHeight) {
        rdRow.current.style.height = ndRow.current.offsetHeight + 'px'
      } else {
        ndRow.current.style.height = rdRow.current.offsetHeight + 'px'
      }
    }
  });

  return (
    <>
      <div className="col-lg-6 border-end brd-gray border-1">
        {renderStories(secondRowData, ndRow)}
      </div>
      <div className="col-lg-6">
        {renderStories(thirdRowData, rdRow)}
      </div>
    </>
  );
};
