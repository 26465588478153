/** @format */

import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

import { Stablecoin } from "../../modules/dataRoom/stablecoin";
import { Box } from "@chakra-ui/react";
import { SDR } from "../../modules/dataRoom/sdr";

const DataRoomPage = () => {
  const location = useLocation();
  const [isLoading, setIsloading] = useState(true);
  const [paramUrl, setParamUrl] = useState(window.location.pathname);

  useEffect(() => {
    setParamUrl(location.pathname);
    setTimeout(() => {
      setIsloading(false);
    }, 1000);
  }, [location, isLoading]);

  return (
    <Box className="container" p={0}>
      {paramUrl === "/data-room/stablecoin" && <Stablecoin />}
      {paramUrl === "/data-room/sdr" && <SDR />}
    </Box>
  );
};

export { DataRoomPage };
