/** @format */

import { useQuery } from "@apollo/client";
import { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import CustomSpinner from "../../../components/CustomSpiner";
import { EVENT_DETAIL_QUERY } from "../../../graphql/queries/events";
import { Button } from "@chakra-ui/react";

export const EventDetailPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data, loading } = useQuery(EVENT_DETAIL_QUERY, {
    variables: {
      id,
    },
  });

  useEffect(() => {
    if (!id) {
      navigate("/home");
      return;
    }
  }, [id, loading]);

  const handleRedirect = (url: string) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <>
      {loading ? (
        <div className="mt-5">
          <CustomSpinner />
        </div>
      ) : (
        <section className="tc-about-about mt-30">
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-lg-6">
                <div className="img img-cover">
                  <img
                    src={
                      process.env.REACT_APP_API_URL +
                      data?.event?.data?.attributes?.banner_img?.data
                        ?.attributes?.url
                    }
                    alt=""
                  />
                </div>
              </div>
              <div className="col-lg-5">
                <div className="info mt-4 mt-lg-0">
                  <p className="fsz-13px color-999 text-uppercase mb-10">
                    {
                      data?.event?.data?.attributes?.organization?.data
                        ?.attributes?.name
                    }
                  </p>
                  <h2 className="fsz-30px mb-40">
                    {data?.event?.data?.attributes?.name}
                  </h2>
                  <div className="text fsz-14px color-666 mb-60">
                    {data?.event?.data?.attributes?.event_blurb}
                  </div>
                  <Button
                    w={"20"}
                    onClick={() =>
                      handleRedirect(data?.event?.data?.attributes?.rsvp_url)
                    }
                  >
                    RSVP
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};
