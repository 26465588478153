/** @format */

import { gql } from "@apollo/client";

export const EVENTS_HOME_QUERY = gql`
  query {
    events {
      data {
        id
        attributes {
          name
          rsvp_url
        }
      }
    }
  }
`;

export const EVENTS_QUERY = gql`
  query {
    events {
      data {
        id
        attributes {
          name
          theme
          banner_img {
            data {
              attributes {
                url
              }
            }
          }
          organization {
            data {
              attributes {
                name
              }
            }
          }
          event_blurb
          rsvp_url
        }
      }
    }
  }
`;
export const EVENT_DETAIL_QUERY = gql`
  query ($id: ID) {
    event(id: $id) {
      data {
        id
        attributes {
          name
          banner_img {
            data {
              attributes {
                url
              }
            }
          }
          organization {
            data {
              attributes {
                name
              }
            }
          }
          event_blurb
          rsvp_url
        }
      }
    }
  }
`;
