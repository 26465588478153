/** @format */
import { useEffect, useState } from "react";
import { SubNavComponent } from "./subnav";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
  Text,
} from "@chakra-ui/react";
import { getUser, postSubscribe } from "../../api/BE";
import { Field, Form, Formik } from "formik";
import { AxiosError } from "axios";
import { useUserProfile } from "../../hook/user";

/** @format */

function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}

export const NavBar = () => {
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const { userProfile, handleUserChange } = useUserProfile();
  const navigate = useNavigate();

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const getCurrentDate = () => {
    const weekday = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    const currentDate = new Date().toDateString().substring(4);
    return (
      <>
        <strong>{weekday[new Date().getDay()]}</strong>
        <p>{currentDate.substring(0, 6) + ", " + currentDate.substring(6)}</p>
      </>
    );
  };

  useEffect(() => {
    const getUserProfile = async () => {
      const { data } = await getUser();
      handleUserChange(data);
    };

    getUserProfile().catch((e: AxiosError) => {
      if (e.response?.status === 400 || e.response?.status === 403) {
        localStorage.removeItem("token");
      } else {
        console.error(e.message);
      }
    });
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate('/home')
    window.location.reload()
  };

  return (
    <>
      <div className="navbar-container">
        <div className="container">
          <div className="top-navbar style-1">
            <div className="container p-0">
              <div className="row align-items-center">
                {windowSize.innerWidth < 960 ? (
                  <>
                    <div className="col-12">
                      <a href="/" className="logo-brand">
                        <img
                          src={process.env.PUBLIC_URL + "/logo.png"}
                          alt=""
                          className="dark-none"
                        />
                        <img
                          src={process.env.PUBLIC_URL + "/logo.png"}
                          alt=""
                          className="light-none"
                        />
                      </a>
                    </div>

                    {/* <div className="col-12">
                      <div className="d-flex mb-3 mt-3 mb-lg-0 justify-content-between align-items-center">
                        <div className="date-weather ">
                          <div className="item">
                            <div className="icon me-3 pt-1">
                              <i className="la la-calendar" />
                            </div>
                            <div className="inf">{getCurrentDate()}</div>
                          </div>
                        </div>
                        <div>
                          <a
                            href="#0"
                            className="text-uppercase fs-6 border-bottom border-1 border-dark subs"
                          >
                            <i className="la la-envelope fs-5 me-1" />
                            Subscribe
                          </a>
                        </div>
                        <div className="sub-darkLight">
                          <DarkLightToggleComponent />
                        </div>
                      </div>
                    </div> */}
                  </>
                ) : (
                  <>
                    <div className="col">
                      <div className="date-weather mb-3 mb-lg-0">
                        <div className="row align-items-center">
                          <div className="col-9">
                            <Formik
                              initialValues={{ email: "" }}
                              onSubmit={async (values, actions) => {
                                await postSubscribe({ email: values.email });
                                actions.resetForm();
                              }}
                            >
                              {(props) => (
                                <Form>
                                  <Flex>
                                    <Field name="email">
                                      {({
                                        field,
                                        form,
                                      }: {
                                        field: any;
                                        form: any;
                                      }) => (
                                        <FormControl
                                          isInvalid={
                                            form.errors.name &&
                                            form.touched.name
                                          }
                                        >
                                          <Input
                                            {...field}
                                            type="email"
                                            border={"1px solid black"}
                                            _hover={{
                                              border: "1px solid black",
                                            }}
                                            borderRadius={"none"}
                                            size={"sm"}
                                            placeholder="Enter email address"
                                          />
                                          <FormErrorMessage>
                                            {form.errors.name}
                                          </FormErrorMessage>
                                        </FormControl>
                                      )}
                                    </Field>
                                    <Button
                                      size={"sm"}
                                      w={"48"}
                                      isLoading={props.isSubmitting}
                                      type="submit"
                                    >
                                      Subscribe
                                    </Button>
                                  </Flex>
                                </Form>
                              )}
                            </Formik>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <a href="/" className="logo-brand d-none d-lg-block">
                        <img
                          src={process.env.PUBLIC_URL + "/logo.png"}
                          alt=""
                          className="dark-none"
                        />
                        <img
                          src={process.env.PUBLIC_URL + "/logo-black.png"}
                          alt=""
                          className="light-none"
                        />
                      </a>
                    </div>
                    <div className="col">
                      <div className="sub-darkLight">
                        <div className="row text-end align-items-center">
                          <div className="col-6"></div>
                          <div className="col-6">
                            {/* <DayNightSwitch /> */}
                            {/* <DarkLightToggleComponent /> */}
                            {userProfile ? (
                              <>
                                <Flex justifyContent={"flex-end"}>
                                  <Text
                                    textDecor={"underline"}
                                    cursor={"pointer"}
                                  >
                                    <Link to="/profile">
                                      {" "}
                                      {userProfile?.username}{" "}
                                    </Link>
                                  </Text>{" "}
                                  &nbsp;|&nbsp;{" "}
                                  <Text
                                    textDecor={"underline"}
                                    cursor={"pointer"}
                                    onClick={() => handleLogout()}
                                  >
                                    Log out
                                  </Text>
                                </Flex>
                              </>
                            ) : (
                              <>
                                <Flex justifyContent={"flex-end"}>
                                  <Text
                                    textDecor={"underline"}
                                    cursor={"pointer"}
                                  >
                                    <Link to="/auth">Login</Link>
                                  </Text>{" "}
                                  &nbsp;|&nbsp;{" "}
                                  <Text
                                    textDecor={"underline"}
                                    cursor={"pointer"}
                                  >
                                    <Link to="/register">Register</Link>
                                  </Text>
                                </Flex>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <SubNavComponent />
        </div>
      </div>
    </>
  );
};
