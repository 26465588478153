/** @format */

import { useQuery } from "@apollo/client";
import { Flex, Text } from "@chakra-ui/react";
import { TOPCTA_QUERY } from "../../graphql/queries/topcta";
import { useDarkLight } from "../../hook/darkLight";

export const Topcta = () => {
  const { data, loading } = useQuery(TOPCTA_QUERY);
  const {darkMode} = useDarkLight()

  const openInNewTab = (url: string) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };
  return (
    <>
      {!loading && data?.topCta?.data ? (
        <>
          <Flex
            width={"100%"}
            justifyContent={"center"}
            alignItems={"center"}
            bg={"main"}
            h={10}
            onClick={() =>
              openInNewTab(data?.topCta?.data?.attributes.link_url)
            }
          >
            <Text fontWeight={"bold"} color={"black"}>
              {data?.topCta?.data?.attributes.description} |&nbsp;
            </Text>
            <Text
              fontWeight={"bold"}
              textDecor={"underline"}
              cursor={"pointer"}
              color={"black"}
            >
              {data?.topCta?.data?.attributes.link_description}
            </Text>
          </Flex>
        </>
      ) : (
        <></>
      )}
    </>
  );
};
