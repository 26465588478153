/** @format */

import { Feature } from "../../modules/feature";
import { HomeReading } from "../../modules/library";

export const HomePage = () => {
  return (
    <>
      <Feature />
      <HomeReading />
      {/* <Podcasts /> */}
      {/* <InterestSection /> */}
    </>
  );
};
