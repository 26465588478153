/** @format */

import { Box, Button, Flex, Text, Link } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getDowloadable, postTrackingDownload } from "../../api/BE";
import { useLazyQuery } from "@apollo/client";
import {
  PINNED_READING_QUERY,
  READING_DETAIL_QUERY,
} from "../../graphql/queries/reading";
import CustomSpinner from "../../components/CustomSpiner";
import { NextNavigate } from "../../App";

export const ReadingLibraryDetailPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { setNextNavigate } = useContext(NextNavigate);

  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<any>();
  const [isDownloadable, setDownloadable] = useState<boolean>(false);

  const [getPinnedReading] = useLazyQuery(PINNED_READING_QUERY, {
    onCompleted(data: any) {
      if (data?.pinnedReading?.data?.id === id) {
        setData(data?.pinnedReading?.data);
        setLoading(false);
      } else {
        getReadingDetail();
      }
    },
  });

  const [getReadingDetail] = useLazyQuery(READING_DETAIL_QUERY, {
    variables: { id },
    onCompleted(data: any) {
      setData(data?.reading?.data);
      setLoading(false);
    },
  });

  const checkDownloadable = async () => {
    const { data } = await getDowloadable();
    setDownloadable(data);
  };

  const handleTrackingDownload = async (id: number) => {
    try {
      await postTrackingDownload({ id });
      await checkDownloadable();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!id) navigate("/home");

    if (!localStorage.getItem("token")) {
      setNextNavigate(`/library/${id}`);
      navigate("/auth");
    }

    getPinnedReading();
    checkDownloadable().catch((err) => console.log(err));
  }, []);
  return (
    <>
      {loading ? (
        <Box m={5}>
          <CustomSpinner />
        </Box>
      ) : (
        <Flex className="container" mt={4} mb={4}>
          <Box mr={4} minW={425}>
            <img src={data?.attributes?.thumbnail_url} alt="" width={425} />
          </Box>
          <Flex flexDirection={"column"} justifyContent={"space-between"}>
            <Box>
              <Text fontSize="3xl">{data?.attributes?.title}</Text>
              <Text>{data?.attributes?.short_description}</Text>
            </Box>

            <Box>
              <Link
                href={isDownloadable ? data?.attributes?.pdf_url : "#"}
                target="_blank"
              >
                <Button
                  w={200}
                  onClick={() => handleTrackingDownload(+data.id)}
                  isDisabled={!isDownloadable}
                >
                  Download
                </Button>
              </Link>

              {!isDownloadable && (
                <Text color={"red.500"}>24 hour download quota exceeded</Text>
              )}
            </Box>
          </Flex>
        </Flex>
      )}
    </>
  );
};
