/** @format */
import { createColumnHelper } from "@tanstack/react-table";
import { Box } from "@chakra-ui/react";
import CustomSpinner from "../../components/CustomSpiner";
import { DataTable } from "../../components/Datatable";
import { useQuery } from "@apollo/client";
import { CBDC_PULSE_SHORT_QUERY } from "../../graphql/queries/monitor";

export const CBDCPulseTable = () => {
  const { data, loading, error } = useQuery(CBDC_PULSE_SHORT_QUERY, {
    variables: {
      limit: 100,
    },
  });

  const columnHelper = createColumnHelper<any>();

  const columns: any = [
    columnHelper.accessor((row) => row?.attributes?.country, {
      cell: (info) => info.getValue(),
      header: "Country",
    }),
    columnHelper.accessor((row) => row?.attributes?.country, {
      cell: (info) => info.getValue(),
      header: "Project",
    }),
    columnHelper.accessor((row) => row?.attributes?.status, {
      cell: (info) => info.getValue(),
      header: "Status",
    }),
    columnHelper.accessor((row) => row?.attributes?.type, {
      cell: (info) => info.getValue(),
      header: "Type",
    }),
    columnHelper.accessor((row) => row?.attributes?.source?.data?.name, {
      cell: (info) => info.getValue(),
      header: "Source",
    }),
  ];

  return loading ? (
    <Box m={5}>
      <CustomSpinner />
    </Box>
  ) : (
    <DataTable columns={columns} data={data?.cbdcMonitors?.data} />
  );
};
