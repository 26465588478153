/** @format */

import { useState } from "react";

export const useDarkLight = () => {
  const [darkMode, setDarkMode] = useState<boolean>(false);

  function handleChange(value: boolean) {
    setDarkMode(value);
  }
  return { darkMode, handleChange };
};
