/** @format */

import { FC } from "react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import { ErrorsPage } from "../pages/ErrorsPage";
import { App } from "../App";
import { HomePage } from "../pages/home";
import { FeatureDetailPage } from "../pages/home/detail/FeatureDetail";
import { DataRoomPage } from "../pages/dataRoom";
import { ReadingLibraryPage } from "../pages/readingLibrary";
import { AuthPage } from "../pages/auth";
import { RegisterPage } from "../pages/auth/register";
import { SubscribePage } from "../pages/subscribe";
import { StoryDetailPage } from "../pages/home/detail/StoryDetail";
import { BookmarkPage } from "../pages/bookmark";
import { ViewsPage } from "../pages/views";
import { EventDetailPage } from "../pages/home/detail/EventDetail";
import { EventPage } from "../pages/event";
import { ProfilePage } from "../pages/profile";
import { SearchPage } from "../pages/search";
import { CBDCPulsePage } from "../pages/cbdcpulse";
import { VerifyAccountPage } from "../pages/auth/verify";
import { ForgotPasswordPage } from "../pages/auth/forgot";
import { ReadingLibraryDetailPage } from "../pages/readingLibrary/detail";
const { PUBLIC_URL } = process.env;

const AppRoutes: FC = () => {
  const setSessionToken = (userToken: string) => {
    localStorage.setItem("token", JSON.stringify(userToken));
  };

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path="error/*" element={<ErrorsPage />} />
          <Route path="home" element={<HomePage />} />
          <Route path="feature/detail/:id" element={<FeatureDetailPage />} />
          <Route path="news/detail/:id" element={<StoryDetailPage />} />
          <Route path="/events" element={<EventPage />} />
          <Route path="/events/:id" element={<EventDetailPage />} />
          <Route path="/data-room/*" element={<DataRoomPage />} />
          <Route path="/library" element={<ReadingLibraryPage />} />
          <Route path="/library/:id" element={<ReadingLibraryDetailPage />} />
          <Route path="/subscribe" element={<SubscribePage />} />
          <Route path="/video" element={<SubscribePage />} />
          <Route path="/bookmark" element={<BookmarkPage />} />
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/search" element={<SearchPage />} />
          <Route path="/cbdcpulse" element={<CBDCPulsePage />} />
          <Route path="/verify/:token" element={<VerifyAccountPage />} />
          <Route path="/forgot" element={<ForgotPasswordPage />} />
          <Route path="/forgot/:token" element={<ForgotPasswordPage />} />
          {/* <Route path="/views" element={<ViewsPage />} /> */}
          <Route
            path="/auth"
            element={<AuthPage setToken={setSessionToken} />}
          />
          <Route
            path="/register"
            element={<RegisterPage setToken={setSessionToken} />}
          />

          <Route path="*" element={<Navigate to="/home" />} />
          <Route index element={<Navigate to="/home" />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export { AppRoutes };
