/** @format */

import { Link, useNavigate } from "react-router-dom";

const SubNavComponent = () => {
  const navigate = useNavigate();

  const handleMoving = (link: string) => {
    if (link.startsWith("#") || !link) return;
    link.startsWith("https") ? window.open(link, "_blank") : navigate(link);
  };
  const navItem = [
    {
      title: "Home",
      link: "/",
      blank: false,
    },
    {
      title: "Learn",
      link: "#",
      blank: false,
      dropdown: [
        {
          title: "Blockchain 101",
          link: "https://blockchain101.com",
          blank: true,
        },
      ],
    },
    {
      title: "Reading Library",
      link: "/library",
      blank: false,
    },
    {
      title: "Events",
      link: "/events",
      blank: false,
    },
    {
      title: "Research",
      link: "/homes",
      blank: true,
    },
    {
      title: "Join",
      link: "#",
      blank: false,
      dropdown: [
        {
          title: "Think Tank",
          link: "https://cbdctt.com/",
          blank: true,
        },
        {
          title: "Write For Us",
          link: "https://scifn.com/write-for-us/",
          blank: true,
        },
      ],
    },
  ];

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light style-1">
        <div className="container p-0">
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              {navItem.map((item) => {
                return (
                  <li
                    key={item.title}
                    className={`nav-item ${item.dropdown ? "dropdown" : ""}`}
                    style={{ position: "unset" }}
                  >
                    <span
                      className={`nav-link ${
                        item.dropdown && "dropdown-toggle"
                      }`}
                      role="button"
                      onClick={() => handleMoving(item.link)}
                    >
                      {item.title}
                    </span>
                    {item.dropdown && (
                      <>
                        <ul className="dropdownMenu">
                          {item.dropdown.map((i) => (
                            <li key={i.title} className="dropdown-element">
                              <span
                                className="dropdown-item"
                                role="button"
                                onClick={() => handleMoving(i.link)}
                              >
                                {i.title}
                              </span>
                            </li>
                          ))}
                        </ul>
                      </>
                    )}
                  </li>
                );
              })}
            </ul>
            <div className="nav-side">
              {/* <Link to={"/search"} className="icon-link search-btn-style1">
                <i className="la la-search fs-4 sOpen-btn" />
                <i className="la la-close fs-4 sClose-btn" />
              </Link> */}
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export { SubNavComponent };
