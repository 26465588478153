/** @format */
import { QueryResult, useQuery } from "@apollo/client";
import Skeleton from "react-loading-skeleton";
import { FEATURE_QUERY } from "../../graphql/queries/feature";
import { Story } from "../story";
import { useNavigate } from "react-router-dom";

export const Feature = () => {
  const { data, loading } = useQuery(FEATURE_QUERY);

  const navigate = useNavigate();

  const navigateDetailFutre = (id: string) => {
    if (id) {
      navigate(`/feature/detail/${id}`);
    }
  };

  return (
    <>
      {/* ====== start Latest news ====== */}
      <section className="tc-latest-news-style1">
        <div className="container">
          <div className="section-content pt-10 pb-30 border-bottom border-1 brd-gray">
            <p className="color-000 text-uppercase mb-10 ltspc-1">
              {" "}
              <span> feature </span>{" "}
              <i className="la la-angle-right ms-1" />
            </p>
            <div className="row">
              <div className="col-lg-5 border-end brd-gray border-1">
                {loading ? (
                  <Skeleton
                    containerClassName="w-100 h-100"
                    height="100%"
                    width="100%"
                  />
                ) : (
                  data?.feature.data && (
                    <div className="tc-post-grid-default">
                      <div className="item">
                        <div className="img img-cover th-330">
                          <img
                            src={
                              data?.feature.data.attributes.header_img_url
                            }
                            alt=""
                          />
                        </div>
                        <div className="content pt-30">
                          <a
                            href="page-blog.html"
                            className="news-cat color-999 fsz-13px text-uppercase mb-10"
                          >
                            CBDC
                          </a>
                          <h2
                            className="title mb-20"
                            onClick={() =>
                              navigateDetailFutre(data?.feature.data.id)
                            }
                            style={{ cursor: "pointer" }}
                          >
                            {data?.feature.data.attributes?.headline}
                          </h2>
                          <div className="text color-666">
                            {data?.feature.data.attributes?.story
                              .replace(/<\/?[^>]+(>|$)/g, "")
                              .replaceAll("&nbsp;", " ")
                              .substring(0, 200)}
                            [...]
                          </div>
                          <div className="meta-bot lh-1 mt-40">
                            <ul className="d-flex">
                              <li className="date me-5">
                                <a href="/">
                                  <i className="la la-calendar me-2" />{" "}
                                  {new Date(
                                    data?.feature.data.attributes.published_dt
                                  )
                                    .toDateString()
                                    .substring(4)}
                                </a>
                              </li>
                              <li className="author me-5">
                                <a href="/">
                                  <i className="la la-user me-2" /> by{" "}
                                  {data?.feature.data.attributes?.writer?.data
                                    .attributes.first_name +
                                    data?.feature.data.attributes?.writer?.data
                                      .attributes.last_name}{" "}
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>
              <div className="col-lg-7 border-end brd-gray border-1 row">
                <Story />
              </div>
              {/* <div className="col-lg-4 border-end brd-gray border-1">
                <div className="tc-post-list-style2">
                  <div className="items">
                    {data &&
                      data?.feature.data.map((item) => (
                        <div
                          className="item"
                          key={item.id}
                          onClick={() => navigateDetailFutre(+item.id)}
                        >
                          <div className="row gx-3 align-items-center">
                            <div className="col-4">
                              <div className="img th-70 img-cover">
                                <img
                                  src={
                                    process.env.REACT_APP_API_URL +
                                    item.attributes.story_img.data.attributes
                                      .url
                                  }
                                  alt=""
                                />
                              </div>
                            </div>
                            <div className="col-8">
                              <div className="content">
                                <div className="news-cat color-999 fsz-13px text-uppercase mb-1">
                                  {
                                    item.attributes.category.data.attributes
                                      .category
                                  }
                                </div>
                                <h5 className="title ltspc--1">
                                  <span className="hover-underline">
                                    {item.attributes.headline}
                                  </span>
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>
      {/* ====== end Latest news ====== */}
    </>
  );
};
