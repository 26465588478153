/** @format */
import {
  Flex,
  Box,
  Stack,
  FormControl,
  InputGroup,
  InputLeftElement,
  Input,
  InputRightElement,
  Button,
  chakra,
  useToast,
  FormErrorMessage,
  Text,
} from "@chakra-ui/react";
import { useState, useContext, useEffect } from "react";

import { FaLock, FaUser } from "react-icons/fa";
import { Field, FieldProps, Form, Formik } from "formik";
import { loginUser } from "../../api/BE";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { NextNavigate } from "../../App";

const CFaLock = chakra(FaLock);
const CFaUser = chakra(FaUser);

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid Email")
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Email is required"),
  password: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Password is required"),
});

export const AuthPage = ({ setToken }: any) => {
  const { nextNavigate } = useContext(NextNavigate);
  const navigate = useNavigate();
  const toast = useToast({
    position: "bottom-right",
  });

  useEffect(() => {
    window.addEventListener("popstate", () => navigate("/home"));
    return window.removeEventListener("popstate", () => navigate("/home"));
  });

  const [showPassword, setShowPassword] = useState(false);

  const handleShowClick = () => setShowPassword(!showPassword);

  return (
    <Flex
      flexDirection="column"
      width="100wh"
      height="100vh"
      justifyContent="center"
      alignItems="center"
    >
      <Stack
        flexDir="column"
        mb="2"
        justifyContent="center"
        alignItems="center"
      >
        <Box minW={{ base: "90%", md: "468px" }}>
          <Formik
            validationSchema={loginSchema}
            initialValues={{
              email: "",
              password: "",
            }}
            onSubmit={async (values, actions) => {
              try {
                values.password = values.password.trim();
                const { data } = await loginUser(values);

                setToken(data.tokens.access.token);
                actions.setSubmitting(false);
                navigate(nextNavigate);
                return;
              } catch (error: any) {
                toast({
                  title: "Incorrect email or password",
                  status: "error",
                });
              }
            }}
          >
            {(props) => (
              <Form>
                <Field name="email">
                  {({ field, form }: FieldProps) => (
                    <FormControl
                      mb={4}
                      isInvalid={!!form.errors.email && !!form.touched.email}
                    >
                      <InputGroup>
                        <InputLeftElement
                          pointerEvents="none"
                          color="black"
                          children={<CFaUser color="black" />}
                        />
                        <Input
                          {...field}
                          placeholder="Email"
                          borderRadius="none"
                        />
                      </InputGroup>
                      <FormErrorMessage>
                        {"" + form.errors.email}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="password">
                  {({ field, form }: FieldProps) => (
                    <FormControl
                      mb={4}
                      isInvalid={
                        !!form.errors.password && !!form.touched.password
                      }
                    >
                      {/* <Input {...field} placeholder="Password" /> */}
                      <InputGroup>
                        <InputLeftElement
                          pointerEvents="none"
                          color="black"
                          children={<CFaLock color="black" />}
                        />
                        <Input
                          {...field}
                          type={showPassword ? "text" : "password"}
                          borderRadius="none"
                          placeholder="Password"
                        />
                        <InputRightElement width="4.5rem">
                          <Button
                            h="1.75rem"
                            size="sm"
                            backgroundColor="black"
                            color="main"
                            borderRadius="none"
                            onClick={handleShowClick}
                          >
                            {showPassword ? "Hide" : "Show"}
                          </Button>
                        </InputRightElement>
                      </InputGroup>
                      <FormErrorMessage>
                        {"" + form.errors.password}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>

                <Flex justifyContent={"end"}>
                  <Link to={"/forgot"}>
                    <Text decoration={"underline"}>Forgot password ?</Text>
                  </Link>
                </Flex>
                <Flex
                  alignItems={"center"}
                  mt={8}
                  justifyContent="space-between"
                >
                  <Button
                    borderRadius="none"
                    type="submit"
                    variant="solid"
                    width="full"
                    backgroundColor="black"
                    color="main"
                    isLoading={props.isSubmitting}
                  >
                    Login
                  </Button>
                </Flex>
              </Form>
            )}
          </Formik>
        </Box>

        <Link to="/register">
          <Text as="u">Register an account</Text>
        </Link>
      </Stack>
    </Flex>
  );
};
