/** @format */

import { Flex, Spinner } from "@chakra-ui/react";

export default function CustomSpinner() {
  return (
    <>
      <Flex
        width="100%"
        height="100%"
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Spinner
          thickness="4px"
          speed="1s"
          emptyColor="black"
          color="main"
          size="xl"
        />
      </Flex>
    </>
  );
}
