/** @format */

import React from "react";
import ReactDOM from "react-dom/client";
import { ApolloProvider, ApolloClient, InMemoryCache } from "@apollo/client";

import reportWebVitals from "./reportWebVitals";

import "./assets/css/lib/ionicons.css";
import "./assets/css/lib/line-awesome.css";
import "./assets/css/lib/animate.css";
import "./assets/css/lib/jquery.fancybox.css";
import "./assets/css/lib/lity.css";
import "./assets/css/lib/swiper.min.css";
import "./assets/css/style.css";
import "react-loading-skeleton/dist/skeleton.css";
import "react-tooltip/dist/react-tooltip.css";
import { AppRoutes } from "./routing/AppRouter";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

const client = new ApolloClient({
  uri: process.env.REACT_APP_API_URL + "/graphql",
  cache: new InMemoryCache(),
  headers: { authorization: "Bearer " + process.env.REACT_APP_FIX_TOKEN },
});
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.REACT_APP_CAPTCHA_SITE || ""}
      >
        <AppRoutes />
      </GoogleReCaptchaProvider>
    </ApolloProvider>
  </React.StrictMode>
);

reportWebVitals();
