/** @format */

import axios from "axios";

const baseURL = process.env.REACT_APP_CI_API_URL;

export const getKucoinStableCoin = async (listPair: any = []) => {
  let endpoint = "/kucoin/stablecoin";

  if(listPair.length) {
    endpoint += `?pairs=${listPair.map((p: any) => p.symbol).join(",")}`
  }

  const respone = await axios.get(baseURL + endpoint);

  if (respone.status === 200) {
    return respone.data;
  }
};

export const getKucoinSocketToken = async () => {
  const endpoint = "/kucoin/get-socket-endpoint";
  const respone = await axios.get(baseURL + endpoint);

  if (respone.status === 200) {
    return respone.data;
  }
};
