/** @format */

import { ReactNode, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { Tooltip } from "react-tooltip";

type Props = {
  text: string;
  width?: string | number;
  children: ReactNode;
};

export const ClipboardCopy: React.FC<Props> = ({
  text,
  width = 17,
  children,
}) => {
  const [isCopy, setIsCopy] = useState(false);

  return (
    <>
      {text && text !== "" ? (
        <>
          <CopyToClipboard
            onCopy={() => {
              setIsCopy(true);
              setTimeout(() => {
                setIsCopy(false);
              }, 1500);
            }}
            text={text}
          >
            <div
              data-tooltip-id={text}
              className="copy-link cursor-pointer"
              onClick={(e) => e.stopPropagation()}
            >
              {children}
            </div>
          </CopyToClipboard>
          <Tooltip id={text} content="Copied" isOpen={isCopy} />
        </>
      ) : (
        ""
      )}
    </>
  );
};
