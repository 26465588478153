/** @format */

import { useState } from "react";

export const useUserProfile = () => {
  const [userProfile, setUser] = useState<any>();

  function handleUserChange(value: any) {
    setUser(value);
  }
  return { userProfile, handleUserChange };
};
