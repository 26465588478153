/** @format */
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import Papa from "papaparse";
import { useEffect, useState } from "react";
import { Box, Spacer, Text } from "@chakra-ui/react";
import { DataTable } from "../../components/Datatable";
import { SDRData } from "../../interface";
import { createColumnHelper } from "@tanstack/react-table";
import CustomSpinner from "../../components/CustomSpiner";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: true,
      text: "SDR Price Per USD",
    },
  },
};

export const SDR = () => {
  const [loading, setLoading] = useState(true);

  const [lineData, setLineData] = useState();
  const [labels, setLabel] = useState();
  const [tableData, setTableData] = useState([]);
  useEffect(() => {
    const fetchParsePapa = async () => {
      Papa.parse(process.env.PUBLIC_URL + "/sdrv.csv", {
        download: true,
        delimiter: ",",
        complete: (result: any) => {
          const formatData = (result.data as any).map((row: any) => {
            return { reportDate: row[0], sdrPerUsd: row[1] };
          });

          setTableData(formatData.slice(1).reverse().slice(1));

          const shortData = formatData.reverse().splice(1, 30).reverse();
          setLineData(shortData.map((p: any) => p.sdrPerUsd));
          setLabel(shortData.map((p: any) => p.reportDate));

          setLoading(false);
        },
      });
    };
    fetchParsePapa();
  }, []);

  const columnHelper = createColumnHelper<SDRData>();

  const columns: any = [
    columnHelper.accessor((row) => row.reportDate, {
      cell: (info) => info.getValue().replace("-", " - "),
      header: "Report Date",
    }),
    columnHelper.accessor((row) => row.sdrPerUsd, {
      cell: (info) => parseFloat(info.getValue()).toFixed(3),
      header: "SDR Per Usd",
    }),
  ];

  return (
    <>
      {loading ? (
        <CustomSpinner />
      ) : (
        <>
          <Line
            options={options}
            data={{
              labels,
              datasets: [
                {
                  label: "SDR Price",
                  data: lineData,
                  borderColor: "red",
                  backgroundColor: "rgba(255, 99, 132, 0.5)",
                },
              ],
            }}
          />

          <Spacer my={10} />

          <Box>
            <Text fontSize={"3xl"} mb="2">
              SDR Price
            </Text>
            <DataTable columns={columns} data={tableData} maxHeight={500}/>
          </Box>
        </>
      )}
    </>
  );
};
