/** @format */
import { Box, Flex, Text } from "@chakra-ui/react";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";

const EventsHome = ({ data }: any) => {
  const monitorRender = () => {
    if (!data.content) {
      return (
        <Skeleton
          count={4}
          height="58px"
          containerClassName="stock-card-body w-100 p-0"
        />
      );
    }

    const listCol = Object.keys(data.content[0].attributes).filter(
      (k) => k !== "__typename"
    );

    return (
      <div className="stock-card-body">
        <table className="table table-borderless">
          <tbody>
            {data.content.map((row: any, idx: number) => (
              <tr key={idx}>
                {listCol.map((col, idx) => {
                  return (
                    <td
                      className="text-black"
                      style={{ padding: "2px 0px" }}
                      key={idx}
                    >
                      {col.toLowerCase() !== "rsvp_url" ? (
                        <Text fontWeight={'bold'}>{row.attributes[col]}</Text> 
                      ) : (
                        <Flex>
                          <Text textDecor={"underline"} cursor={"pointer"}>
                            <Link target="_blank" to={`/events/${row.id}`}>
                              Details
                            </Link>
                          </Text>{" "}
                          &nbsp;|&nbsp;{" "}
                          <Text textDecor={"underline"} cursor={"pointer"}>
                            <Link target="_blank" to={row.attributes[col]}>
                              Register
                            </Link>
                          </Text>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </Flex>
                      )}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <>
      <Box h={"90%"} className="tc-trends-news-style1 flex-grow-1">
        <Box h={"100%"} bg={"main"} p={1} className="overflow-auto">
          {monitorRender()}
        </Box>
      </Box>
    </>
  );
};

const EventsHomeWrapper: React.FC<any> = (props) => {
  return <EventsHome data={props} />;
};

export { EventsHomeWrapper };
