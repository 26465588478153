/** @format */

import { Box } from "@chakra-ui/react";
import { CBDCPulseTable } from "../../modules/cbdcpulse";

const CBDCPulsePage = () => {
  return (
    <Box className="container" mb={5}>
      <CBDCPulseTable />
    </Box>
  );
};

export { CBDCPulsePage };
