/** @format */

import { defineStyleConfig } from "@chakra-ui/react";

export const buttonTheme = defineStyleConfig({
  baseStyle: {
    fontWeight: "normal",
    borderRadius: "none",
    border: "1px solid black",
  },
  variants: {
    solid: {
      bg: "black",
      color: "main",
      _hover: {
        bgColor: "main",
        color: "black",
      },
    },
  },
  // The default size and variant values
  defaultProps: {
    variant: "solid",
  },
});
