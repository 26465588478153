/** @format */
import { gql } from "@apollo/client";

export const CREATE_USER_MUTATION = gql`
  mutation createUser(
    $username: String!
    $email: String!
    $password: String!
    $confirmed: Boolean
    $role: ID
  ) {
    createUsersPermissionsUser(
      data: {
        username: $username
        email: $email
        password: $password
        role: $role
        confirmed: $confirmed
      }
    ) {
      data {
        id
        attributes {
          username
          email
          role {
            data {
              attributes {
                name
              }
            }
          }
        }
      }
    }
  }
`;
