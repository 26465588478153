/** @format */

import { gql } from "@apollo/client";

export const CBDC_PULSE_SHORT_QUERY = gql`
  query GetCBDCPulseShorts($limit: Int = 5) {
    cbdcMonitors(pagination: { limit: $limit }) {
      data {
        id
        attributes {
          country
          project
          status
          type
          source {
            data {
              attributes {
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const STABLE_COIN_PULSE_SHORT_QUERY = gql`
  query GetStableCoinPulseShorts {
    stablecoins {
      data {
        id
        attributes {
          symbol
          display_price
          home_url
        }
      }
    }
  }
`;
