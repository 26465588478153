/** @format */

import { Bookmark } from "../../modules/bookmark";

export const BookmarkPage = () => {
  return (
    <>
      <Bookmark />
    </>
  );
};
