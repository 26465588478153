/** @format */

import { EventTable } from "../../modules/events/table";

export const EventPage = () => {
  return (
    <>
      <EventTable />
    </>
  );
};
