/** @format */
import { useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";

const StableMonitorComponent = ({ data }: any) => {
  const [title, setTitle] = useState(data.title);

  const monitorRender = () => {
    if (!data.content) {
      return (
        <Skeleton
          count={4}
          height="58px"
          containerClassName="stock-card-body w-100 p-0"
        />
      );
    }

    const listCol = Object.keys(data.content[0].attributes).filter(
      (k) => k !== "__typename"
    );

    return (
      <div className="stock-card-body">
        <div>{}</div>
        <table className="table table-borderless">
          <thead>
            {listCol.map((col, idx) => {
              if (col.toLowerCase() === "home_url") return <></>;
              else {
                return (
                  <th className="text-capitalize pt-2" key={idx}>
                    {col.toLowerCase() === "display_price" ? "Price" : col}
                  </th>
                );
              }
            })}
          </thead>
          <tbody>
            {data.content.map((row: any, idx: number) => (
              <tr key={idx}>
                {listCol.map((col, idx) => {
                  if (col.toLowerCase() === "home_url") return <></>;
                  return (
                    <td style={{ padding: "12px 0px" }} key={idx}>
                      <a
                        href={row.attributes[listCol[2]]}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {row.attributes[col]}
                      </a>
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <>
      <div className="tc-trends-news-style1 flex-grow-1">
        <div className="tc-stock-card mb-30 overflow-auto">
          <Link to="/data-room/stablecoin" className="stock-card-title">
            {title}
            <span className="la la-arrow-circle-o-right color-666" />
          </Link>
          {monitorRender()}
        </div>
      </div>
    </>
  );
};

const StableMonitorWrapper: React.FC<any> = (props) => {
  return <StableMonitorComponent data={props} />;
};

export { StableMonitorWrapper };
