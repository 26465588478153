/** @format */

import { createContext, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { NavBar } from "./modules/navbar";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "./theme";
import { Topcta } from "./modules/topcta";
import { Footer } from "./modules/footer";

export const NextNavigate = createContext<any>(null);

function App() {
  const location = useLocation();
  const disableNav = ["/auth", "/register", "/verify", "/forgot"];
  const [nextNavigate, setNextNavigate] = useState<string>("/home");
  return (
    <NextNavigate.Provider
      value={{
        nextNavigate,
        setNextNavigate,
      }}
    >
      <ChakraProvider theme={theme}>
        {disableNav.includes(location.pathname) ||
        location.pathname.startsWith("/forgot/") ||
        location.pathname.startsWith("/verify/") ? (
          <>
            <Outlet />
          </>
        ) : (
          <>
            <Topcta />
            <NavBar />
            <Outlet />
            <Footer />
          </>
        )}
      </ChakraProvider>
    </NextNavigate.Provider>
  );
}

export { App };
