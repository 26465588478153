/** @format */
import {
  Flex,
  Box,
  Stack,
  FormControl,
  InputGroup,
  InputLeftElement,
  Input,
  Button,
  chakra,
  FormErrorMessage,
  useToast,
} from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";

import { FaUser } from "react-icons/fa";
import { AiOutlineMail } from "react-icons/ai";
import { Field, FieldProps, Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { postSubscribe } from "../../api/BE";
import { GoogleReCaptcha, useGoogleReCaptcha } from "react-google-recaptcha-v3";

const CFaUser = chakra(FaUser);
const CAiOutlineMail = chakra(AiOutlineMail);

export const Subscribe = ({ setToken }: any) => {
  const navigate = useNavigate();
  const [verify, setVerify] = useState(false);
  const toast = useToast({
    position: "bottom-right",
  });

  const [verifyToken, setVerifyToken] = useState("");
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);

  const onVerify = useCallback((token: any) => {
    setVerifyToken(token);
  }, []);

  function validate(value: string) {
    let error;
    if (!value) {
      error = " is required";
    }
    return error;
  }
  return (
    <>
      <Flex
        flexDirection="column"
        width="100wh"
        height="100vh"
        justifyContent="center"
        alignItems="center"
      >
        <Stack
          flexDir="column"
          mb="2"
          justifyContent="center"
          alignItems="center"
        >
          <Box minW={{ base: "90%", md: "468px" }}>
            <Formik
              initialValues={{
                fullname: "",
                email: "",
              }}
              onSubmit={async (values, actions) => {
                if (!verifyToken) {
                  toast({
                    title: "Verify captcha",
                    status: "error",
                  });
                  actions.setSubmitting(false);
                  return;
                }

                const { data } = await postSubscribe(values);

                setToken(data.tokens.access.token);
                setRefreshReCaptcha(true);
                navigate("/home");
                actions.setSubmitting(false);
                return;
              }}
            >
              {(props) => (
                <Form>
                  <Field name="fullname" validate={validate}>
                    {({ field, form }: FieldProps) => (
                      <FormControl
                        mb={4}
                        isInvalid={
                          !!form.errors.fullname && !!form.touched.fullname
                        }
                      >
                        <InputGroup>
                          <InputLeftElement
                            pointerEvents="none"
                            color="black"
                            children={<CFaUser color="black" />}
                          />
                          <Input
                            {...field}
                            placeholder="Full Name"
                            borderRadius="none"
                          />
                        </InputGroup>
                        <FormErrorMessage>
                          {"Full Name" + form.errors.fullname}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="email" validate={validate}>
                    {({ field, form }: FieldProps) => (
                      <FormControl
                        mb={4}
                        isInvalid={!!form.errors.email && !!form.touched.email}
                      >
                        <InputGroup>
                          <InputLeftElement
                            pointerEvents="none"
                            color="black"
                            children={<CAiOutlineMail color="black" />}
                          />

                          <Input
                            {...field}
                            placeholder="Email"
                            borderRadius={0}
                          />
                        </InputGroup>
                        <FormErrorMessage>
                          {"Email" + form.errors.email}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Flex justifyContent={"center"}>
                    <GoogleReCaptcha
                      onVerify={onVerify}
                      refreshReCaptcha={refreshReCaptcha}
                    />
                  </Flex>
                  <Flex
                    alignItems={"center"}
                    mt={8}
                    justifyContent="space-between"
                  >
                    <Button
                      type="submit"
                      width="full"
                      isLoading={props.isSubmitting}
                    >
                      Subscribe
                    </Button>
                  </Flex>
                </Form>
              )}
            </Formik>
          </Box>
        </Stack>
      </Flex>
    </>
  );
};
