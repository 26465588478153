import { gql } from "@apollo/client";

export const TOPCTA_QUERY = gql`
  query GetTopCta {
    topCta {
      data {
        id
        attributes {
          description
          link_description
          link_url
        }
      }
    }
  }
`;