/** @format */
import { useEffect } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { getVerifyUser } from "../../api/BE";
import { AxiosResponse } from "axios";

export const VerifyAccountPage = () => {
  const navigate = useNavigate();

  const { token } = useParams();
  useEffect(() => {
    if (!token) {
      navigate("/auth");
    }

    const asyncFunction = async () => {
      const res: AxiosResponse = await getVerifyUser(token || "");
      if (res.status === 200) {
        navigate("/home");
      }
    };

    asyncFunction().catch((err) => console.log(err));
  }, []);

  return <></>;
};
