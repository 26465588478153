/** @format */
import {
  Flex,
  Box,
  Stack,
  FormControl,
  InputGroup,
  InputLeftElement,
  Input,
  InputRightElement,
  Button,
  chakra,
  useToast,
  FormErrorMessage,
} from "@chakra-ui/react";
import { useState, useContext } from "react";

import { FaLock, FaUser } from "react-icons/fa";
import { AiOutlineMail } from "react-icons/ai";
import { Field, FieldProps, Form, Formik } from "formik";
import { registerUser } from "../../api/BE";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { CREATE_USER_MUTATION } from "../../graphql/queries/user";
import * as Yup from "yup";
import { NextNavigate } from "../../App";

const CFaLock = chakra(FaLock);
const CFaUser = chakra(FaUser);
const CAiOutlineMail = chakra(AiOutlineMail);

const registrationSchema = Yup.object().shape({
  username: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Full name is required"),
  email: Yup.string()
    .email("Invalid Email")
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Email is required"),
  password: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Password is required"),
});

export const RegisterPage = ({ setToken }: any) => {
  const { nextNavigate } = useContext(NextNavigate);
  const navigate = useNavigate();
  const toast = useToast({
    position: "bottom-right",
  });

  const [createUser] = useMutation(CREATE_USER_MUTATION);

  const [showPassword, setShowPassword] = useState(false);

  const handleShowClick = () => setShowPassword(!showPassword);
  function validate(value: string) {
    let error;
    if (!value) {
      error = " is required";
    }
    return error;
  }
  return (
    <Flex
      flexDirection="column"
      width="100wh"
      height="100vh"
      justifyContent="center"
      alignItems="center"
    >
      <Stack
        flexDir="column"
        mb="2"
        justifyContent="center"
        alignItems="center"
      >
        <Box minW={{ base: "90%", md: "468px" }}>
          <Formik
            initialValues={{
              username: "",
              email: "",
              password: "",
            }}
            validationSchema={registrationSchema}
            onSubmit={async (values, actions) => {
              try {
                values.password = values.password.trim();

                const { data } = await registerUser(values);

                createUser({
                  variables: {
                    username: values.username,
                    email: values.email,
                    password: values.email,
                    confirmed: true,
                    role: 1,
                  },
                });

                setToken(data.tokens.access.token);
                navigate(nextNavigate);
                actions.setSubmitting(false);
                return;
              } catch (error: any) {
                toast({
                  title: error?.response?.status === 400 ? "Username or Email already exists"  : "Internal Server Error",
                  status: "error",
                });
              }
            }}
          >
            {(props) => (
              <Form>
                <Field name="username" validate={validate}>
                  {({ field, form }: FieldProps) => (
                    <FormControl
                      mb={4}
                      isInvalid={
                        !!form.errors.username && !!form.touched.username
                      }
                    >
                      <InputGroup>
                        <InputLeftElement
                          pointerEvents="none"
                          color="black"
                          children={<CFaUser color="black" />}
                        />
                        <Input
                          {...field}
                          placeholder="Full Name"
                          borderRadius="none"
                        />
                      </InputGroup>
                      <FormErrorMessage>
                        {"" + form.errors.username}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="email" validate={validate}>
                  {({ field, form }: FieldProps) => (
                    <FormControl
                      mb={4}
                      isInvalid={!!form.errors.email && !!form.touched.email}
                    >
                      <InputGroup>
                        <InputLeftElement
                          pointerEvents="none"
                          color="black"
                          children={<CAiOutlineMail color="black" />}
                        />
                        <Input
                          {...field}
                          placeholder="Email"
                          borderRadius="none"
                        />
                      </InputGroup>
                      <FormErrorMessage>
                        {"" + form.errors.email}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="password" validate={validate}>
                  {({ field, form }: FieldProps) => (
                    <FormControl
                      mb={4}
                      isInvalid={
                        !!form.errors.password && !!form.touched.password
                      }
                    >
                      {/* <Input {...field} placeholder="Password" /> */}
                      <InputGroup>
                        <InputLeftElement
                          pointerEvents="none"
                          color="black"
                          children={<CFaLock color="black" />}
                        />
                        <Input
                          {...field}
                          type={showPassword ? "text" : "password"}
                          placeholder="Password"
                          borderRadius="none"
                        />
                        <InputRightElement width="4.5rem">
                          <Button
                            h="1.75rem"
                            size="sm"
                            onClick={handleShowClick}
                          >
                            {showPassword ? "Hide" : "Show"}
                          </Button>
                        </InputRightElement>
                      </InputGroup>
                      <FormErrorMessage>
                        {"" + form.errors.password}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Flex
                  alignItems={"center"}
                  mt={8}
                  justifyContent="space-between"
                >
                  <Button
                    type="submit"
                    variant="solid"
                    backgroundColor="black"
                    color="main"
                    borderRadius="none"
                    width="full"
                    isLoading={props.isSubmitting}
                  >
                    Register
                  </Button>
                </Flex>
              </Form>
            )}
          </Formik>
        </Box>
      </Stack>
    </Flex>
  );
};
