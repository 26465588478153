/** @format */

import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Flex,
  FormControl,
  FormErrorMessage,
  Heading,
  Input,
  Text,
} from "@chakra-ui/react";
import { useUserProfile } from "../../hook/user";
import { useEffect } from "react";
import { getUser, putUserResetPassword } from "../../api/BE";
import { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import { Field, Form, Formik } from "formik";

export const SearchPage = () => {
  return (
    <Box m={5}>
      <Flex justifyContent={"center"}>
        <Formik
          initialValues={{ search: "" }}
          onSubmit={async (values, actions) => {
            actions.resetForm();
          }}
        >
          {(props) => (
            <Form>
              <Flex>
                <Field name="search">
                  {({ field, form }: { field: any; form: any }) => (
                    <FormControl
                      isInvalid={form.errors.search && form.touched.search}
                    >
                      <Input
                        {...field}
                        type="text"
                        border={"1px solid black"}
                        _hover={{
                          border: "1px solid black",
                        }}
                        mb={4}
                        borderRadius={"none"}
                        size={"sm"}
                        placeholder="Search"
                      />
                      <FormErrorMessage>{form.errors.search}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Button
                  size={"sm"}
                  w={"48"}
                  isLoading={props.isSubmitting}
                  type="submit"
                >
                  Search
                </Button>
              </Flex>
            </Form>
          )}
        </Formik>
      </Flex>
    </Box>
  );
};
