/** @format */

import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Flex,
  FormControl,
  FormErrorMessage,
  Heading,
  Input,
  Text,
} from "@chakra-ui/react";
import { useUserProfile } from "../../hook/user";
import { useEffect } from "react";
import { getUser, putUserResetPassword } from "../../api/BE";
import { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import { Field, Form, Formik } from "formik";

export const ProfilePage = () => {
  const { userProfile, handleUserChange } = useUserProfile();
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem("token")) return navigate("/auth");

    const getUserProfile = async () => {
      const { data } = await getUser();
      handleUserChange(data);
    };

    getUserProfile().catch((e: AxiosError) => {
      if (e.response?.status === 400 || e.response?.status === 403) {
        localStorage.removeItem("token");
        navigate("/auth");
      } else {
        console.error(e.message);
      }
    });
  }, []);

  const validate = (value: string) => {
    let error;
    if (!value) {
      error = " is required";
    }
    return error;
  };

  return (
    <>
      <Card>
        <CardHeader>
          <Flex>
            <Flex>
              <Box px={3}>
                <Heading size="md">{userProfile?.username}</Heading>
                <Text>{userProfile?.email}</Text>
              </Box>
            </Flex>
          </Flex>
        </CardHeader>

        <CardBody boxSize={"sm"} margin={"auto"}>
          <Formik
            initialValues={{
              oldPassword: "",
              newPassword: "",
              confirmPassword: "",
            }}
            onSubmit={async (values, actions) => {
              if (values.newPassword !== values.confirmPassword) {
                actions.setFieldError("newPassword", "does not match");
                actions.setFieldError("confirmPassword", "does not match");
                return;
              }

              try {
                await putUserResetPassword({
                  oldPassword: values.oldPassword,
                  newPassword: values.newPassword,
                });
              } catch (error: any) {
                if (error?.response?.status === 400) {
                  actions.setFieldError("oldPassword", "is incorrect");
                }
                return;
              }

              actions.resetForm();
            }}
          >
            {(props) => (
              <Form className="d-flex flex-column align-items-center">
                <Field name="oldPassword" validate={validate}>
                  {({ field, form }: { field: any; form: any }) => (
                    <FormControl
                      mb={4}
                      isInvalid={
                        form.errors.oldPassword && form.touched.oldPassword
                      }
                    >
                      <Input
                        {...field}
                        type="password"
                        border={"1px solid black"}
                        _hover={{
                          border: "1px solid black",
                        }}
                        borderRadius={"none"}
                        size={"sm"}
                        placeholder="Current Password"
                      />
                      <FormErrorMessage>
                        Current password {form.errors.oldPassword}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>

                <Field name="newPassword" validate={validate}>
                  {({ field, form }: { field: any; form: any }) => (
                    <FormControl
                      mb={4}
                      isInvalid={
                        form.errors.newPassword && form.touched.newPassword
                      }
                    >
                      <Input
                        {...field}
                        type="password"
                        border={"1px solid black"}
                        _hover={{
                          border: "1px solid black",
                        }}
                        borderRadius={"none"}
                        size={"sm"}
                        placeholder="New Password"
                      />
                      <FormErrorMessage>
                        New password {form.errors.newPassword}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>

                <Field name="confirmPassword" validate={validate}>
                  {({ field, form }: { field: any; form: any }) => (
                    <FormControl
                      mb={4}
                      isInvalid={
                        form.errors.confirmPassword &&
                        form.touched.confirmPassword
                      }
                    >
                      <Input
                        {...field}
                        type="password"
                        border={"1px solid black"}
                        _hover={{
                          border: "1px solid black",
                        }}
                        borderRadius={"none"}
                        size={"sm"}
                        placeholder="Confirm Password"
                      />
                      <FormErrorMessage>
                        Confirm password {form.errors.confirmPassword}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>

                <Button
                  size={"sm"}
                  w={"48"}
                  isLoading={props.isSubmitting}
                  type="submit"
                >
                  Change password
                </Button>
              </Form>
            )}
          </Formik>
        </CardBody>
      </Card>
    </>
  );
};
